$(document).ready(function () {
    var menu = $('.navbar');

    window.addEventListener('scroll', function () {
        var height = $(window).scrollTop(),
            menuHeight = $('.navbar').outerHeight(),
            ht = $('#header-home').height() || menuHeight;

        if (height > ht - menuHeight) {
            menu.addClass('affix');
            $('.navbar-brand > img').attr('src', '/static/img/logo.png');
            $('.navbar-collapse > ul > li > a').css('color', 'white');
            $('#dropdownMenuButton').css('color', 'white');
            $('.navbar-toggler').removeClass('drawer-dark');

            if(!($('.template-homepage').length || $('.full-gallery').length || $('.background').length)) {
                $('body').parent().css('margin-top', '86px');
            }
        } else {
            if($('.nav-content').find('.show').length > 0) {
                menu.addClass('affix');
            } else {
                menu.removeClass('affix');

                if($('.navbar-collapse').attr('id') == 'menu-simple') {
                    $('.navbar-brand > img').attr('src', '/static/img/logo_dark.png');
                    $('.navbar-collapse > ul > li > a').css('color', '#7c7d6b');
                    $('#dropdownMenuButton').css('color', '#7c7d6b');
                    $('.navbar-toggler').addClass('drawer-dark');
                }
            }

            if(!($('.template-homepage').length || $('.full-gallery').length || $('.background').length)) {
                $('body').parent().css('margin-top', '0px');
            }
        }
    });

    var descriptionTruncated = true;
    var description = $('.special-text > .rich-text');

    var dotAPI = description.dotdotdot({
        height: 100,
        watch: false,
        ellipsis: '*...*',
    }).data('dotdotdot');

    if(description.text().includes('*...*')) {
        description.html(description.html().substr(0, description.html().length - 17) + '(...)');
        description.children().last().append('<a class="expand-button">' + translations.read_more + '</a>')
    }

    $(document).on('click', '.expand-button', function() {
        if(descriptionTruncated) {
            dotAPI.destroy();

            $('.expand-button').remove();
            description.children().last().append('<a class="expand-button">' + translations.read_less + '</a>');

            descriptionTruncated = !descriptionTruncated;
        } else {
            dotAPI.truncate();

            $('.expand-button').remove();
            description.html(description.html().substr(0, description.html().length - 17) + '(...)');
            description.children().last().append('<a class="expand-button">' + translations.read_more + '</a>');
            descriptionTruncated = !descriptionTruncated;
        }
    });

    $('#newsletter-form').submit(function (e) {
        e.preventDefault();

        var email = $('.newsletter-input')[0];

        $('#newsletter-form > .input-group').append('<i class="fas fa-spinner fa-spin newsletter-load d-flex align-self-center"></i>');
        $('.newsletter').append('<p class="newsletter-response"></p>');

        $.ajax({
            url: '/subscribe-newsletter/',
            data: {
                email: email.value
            },
            type: 'POST',
            success: function (data) {
                email.value = '';
                $('.fa-spinner').remove();
                $('.newsletter-response')[0].innerText = translations.newsletter.success;
            },
            error: function (data) {
                $('.fa-spinner').remove();

                if(data.responseJSON.error == 'already_subscribed') {
                    $('.newsletter-response')[0].innerText = translations.newsletter.already_subscribed;
                } else {
                    $('.newsletter-response')[0].innerText = translations.newsletter.error;
                }
            }
        });

        return false;
    });

    $('.navbar-toggler').on('click', function () {
        menu.addClass('affix');
        $('.navbar-brand > img').attr('src', '/static/img/logo.png');
        $('.navbar-collapse > ul > li > a').css('color', 'white');
        $('#dropdownMenuButton').css('color', 'white');
        $('.navbar-toggler').removeClass('drawer-dark');

        if(!($('.template-homepage').length || $('.full-gallery').length || $('.background').length)) {
            $('body').parent().css('margin-top', '86px');
        }
    });

    $('.nav-item > .nav-link').on('click', function () {
        if($(this).find('.fa-search').length != 0) {
            $('.search-form').toggleClass('hidden');

            $('.nav-item > .nav-link > .fa-search').toggleClass('active-icon');
        }
    });

    $('.dropdown-toggle').click(function() {
        $('.search-form').addClass('hidden');
    });

    $('.dropdown > .dropdown-menu > .dropdown-item').hover(function() {
        $(this).parent().parent().children('a, button').css('color', '#1ec7ea');
    }, function() {
        if($('.navbar').hasClass('affix')) {
            $(this).parent().parent().children('a, button').css('color', 'white');
        } else {
            if($('.navbar-collapse').attr('id') == 'menu-simple') {
                $(this).parent().parent().children('a, button').css('color', '#7c7d6b');
            } else {
                $(this).parent().parent().children('a, button').css('color', 'white');
            }
        }
    });
});
